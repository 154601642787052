import 'flag-icons/css/flag-icons.min.css'

const localeToFlag: Record<string, string> = {
	en: 'gb',
	zh: 'cn'
}

const getFlag = (locale: string): string =>
	localeToFlag[locale] ? localeToFlag[locale] : locale

const FlagIcon = (props: { countryCode: string }) => (
	<span
		className={`fi fi-${getFlag(props.countryCode)}`}
		style={{ width: '70%', height: '70%' }}
	></span>
)

export default FlagIcon
